import { Box, Button, Dialog, DialogActions, DialogContent, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { BlodRed } from "../../bblib/component/BlodRed";
import { RoleKind } from "../../model/Role";
import { actionSetShowOutOfLimitDialog } from "../../store/dialog/reducer";
import { selectShowOutOfLimitDialog } from "../../store/dialog/selector";
import { selectUser } from "../../store/user/selector";
import outOfLimitImg from "./asset/out_of_limit.jpg";
import { BuySVIPButton } from "./component/BuySVIPButton";
import { BuyVIPButton } from "./component/BuyVIPButton";
import { RightsButton } from "./component/RightsButton";

export function OutOfLimitDialog() {
    const dispatch = useDispatch();

    const param = useSelector(selectShowOutOfLimitDialog);
    const user = useSelector(selectUser);

    const onClose = () => {
        dispatch(actionSetShowOutOfLimitDialog({ show: false, subject: "", roleName: "" }));
    };
    const onConfirm = () => {
        dispatch(actionSetShowOutOfLimitDialog({ show: false, subject: "", roleName: "" }));
    };

    return (
        <Dialog open={param.show} onClose={onClose} fullWidth maxWidth="md">
            <DialogContent>
                <Box
                    sx={{
                        fontSize: 26,
                        mt: "20px",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    您当前是 <BlodRed content={param.roleName} />，
                    <BlodRed content={param.subject} />
                    &nbsp;今日额度已耗尽，
                    <BlodRed content="其余权益" />
                    可正常使用~
                </Box>
                <Stack>
                    <Box sx={{ ml: "100px" }}>
                        {user.authority.role_kind < RoleKind.VIP && (
                            <BuyVIPButton onClick={onClose} />
                        )}
                    </Box>
                    <Box sx={{ mt: "20px", ml: "100px" }}>
                        {user.authority.role_kind < RoleKind.SVIP && (
                            <BuySVIPButton onClick={onClose} />
                        )}
                    </Box>
                    <Box sx={{ mt: "20px", ml: "115px" }}>
                        <RightsButton />
                    </Box>
                </Stack>
                <Box sx={{ mt: "60px", justifyContent: "center" }}>
                    <img src={outOfLimitImg} width="800px" alt="升级会员图片" />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onConfirm} autoFocus>
                    好的
                </Button>
            </DialogActions>
        </Dialog>
    );
}
