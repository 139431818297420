import { AnyAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Craft } from "../../model/Craft";
import { Exam } from "../../model/exam/Exam";
import { ExamResult } from "../../model/exam/result/Result";
import {
    Analysis,
    Answer,
    InputMethod,
    InquiryData,
    PassageData,
} from "../../model/question/data/QuestionData";
import { Question, QuestionResult, QuestionState } from "../../model/question/Question";

export function isExamAction(action: AnyAction): boolean {
    return action.type.startsWith("exam_runtime");
}

export enum ExamStage {
    StartIntro = 0,
    InExam = 1,
    EndIntro = 2,
    // Rest = 3,
    Review = 4,
    Result = 5, // 查看考试结果
}

export const examRuntimeSlice = createSlice({
    name: "exam_runtime",
    initialState: {
        examID: "",
        exam: new Exam(),
        showResult: false,
        stage: ExamStage.StartIntro,

        sectionIndex: 0,
        questionIndex: 0,
        pageIndex: 1,

        reviewIndex: 0,
        fromURL: "",
        createDialog: false,
    },
    reducers: {
        actionCleanExamRuntime: (state) => {
            state.examID = "";
            state.exam = new Exam();
            state.showResult = false;
            state.stage = ExamStage.StartIntro;
            state.sectionIndex = 0;
            state.questionIndex = 0;
            state.pageIndex = 1;
            state.reviewIndex = 0;
            state.fromURL = "";
        },
        actionSetExamShowResult: (state, action: PayloadAction<boolean>) => {
            state.showResult = action.payload;
        },
        actionSetExamIDOrigin: (state, action: PayloadAction<string>) => {
            state.examID = action.payload;
        },
        actionSetExam: (state, action: PayloadAction<Exam>) => {
            state.exam = action.payload;
            state.exam.is_backend_data = true;
            state.sectionIndex = action.payload.cursor.section_index;
            state.questionIndex = action.payload.cursor.question_index;
            state.pageIndex = action.payload.cursor.question_index + 1;

            state.examID = action.payload.id;
        },
        actionSetExamResult: (state, action: PayloadAction<ExamResult>) => {
            state.exam = { ...state.exam, result: action.payload };
        },
        actionSetExamStage: (state, action: PayloadAction<ExamStage>) => {
            state.stage = action.payload;
        },
        _actionSetSectionIndex: (state, action: PayloadAction<number>) => {
            state.sectionIndex = action.payload;
        },
        actionSetPageIndex: (state, action: PayloadAction<number>) => {
            state.pageIndex = action.payload;
            const questionIndex = action.payload - 1;
            const s = state.exam.sections[state.sectionIndex];
            if (0 <= questionIndex && questionIndex < s.questions.length) {
                state.questionIndex = questionIndex;
            } else {
                state.questionIndex = 0;
            }

            // update question state
            const q = s.questions[state.questionIndex];
            if (q.action.state === QuestionState.NotEncountered) {
                q.action.state = QuestionState.NotAnswered;
            }
        },
        actionSetReviewIndex: (state, action: PayloadAction<number>) => {
            state.reviewIndex = action.payload;
        },
        actionSetSectionFinish: (state) => {
            const s = state.exam.sections[state.sectionIndex];
            s.action.finished = true;
        },
        actionSetSectionUsedTime: (state, action: PayloadAction<number>) => {
            const s = state.exam.sections[state.sectionIndex];
            s.action.used_time_seconds = action.payload;
        },
        actionSetExamQuestion: (state, action: PayloadAction<Question>) => {
            const s = state.exam.sections[state.sectionIndex];
            s.questions[state.questionIndex] = action.payload;
        },
        _actionSetConnectWordCraft: (state, action: PayloadAction<{ id: string; c: Craft }>) => {
            for (let i = 0; i < state.exam.sections.length; i++) {
                const section = state.exam.sections[i];
                for (let j = 0; j < section.questions.length; j++) {
                    const question = section.questions[j];
                    for (let k = 0; k < question.connect_words.length; k++) {
                        const w = question.connect_words[k];
                        if (w.info.id === action.payload.id) {
                            state.exam.sections[i].questions[j].connect_words[k].craft =
                                action.payload.c;
                        }
                    }
                }
            }
        },
        _actionSetQuestionFlag: (state, action: PayloadAction<boolean>) => {
            const s = state.exam.sections[state.sectionIndex];
            const q = s.questions[state.questionIndex];
            q.action.flagged = action.payload;
        },
        _actionSetQuestionAnswer: (state, action: PayloadAction<Answer>) => {
            const s = state.exam.sections[state.sectionIndex];
            const q = s.questions[state.questionIndex];
            q.action.answer = action.payload;
            q.action.state = QuestionState.Answered;
        },
        _actionSetQuestionResult: (
            state,
            action: PayloadAction<{ si: number; qi: number; result: QuestionResult }>
        ) => {
            const s = state.exam.sections[action.payload.si];
            const q = s.questions[action.payload.si];
            q.result = action.payload.result;
        },
        _actionAddQuestionUsedTime: (state, action: PayloadAction<number>) => {
            const s = state.exam.sections[state.sectionIndex];
            const q = s.questions[state.questionIndex];
            q.action.used_time_seconds += action.payload;
        },
        _actionSetPassage: (state, action: PayloadAction<PassageData>) => {
            const s = state.exam.sections[state.sectionIndex];
            const q = s.questions[state.questionIndex];
            q.data.passage = action.payload;
        },
        _actionSetInquiry: (state, action: PayloadAction<InquiryData>) => {
            const s = state.exam.sections[state.sectionIndex];
            const q = s.questions[state.questionIndex];
            q.data.inquiry = action.payload;
        },
        _actionSetInput: (state, action: PayloadAction<InputMethod>) => {
            const s = state.exam.sections[state.sectionIndex];
            const q = s.questions[state.questionIndex];
            q.data.input_method = action.payload;
        },
        _actionSetAnalysis: (state, action: PayloadAction<Analysis>) => {
            const s = state.exam.sections[state.sectionIndex];
            const q = s.questions[state.questionIndex];
            q.data.analysis = action.payload;
        },
        actionSetFromURL: (state, action: PayloadAction<string>) => {
            state.fromURL = action.payload;
        },
        actionSetCreateDialog: (state, action: PayloadAction<boolean>) => {
            state.createDialog = action.payload;
        },
    },
});

export const {
    actionCleanExamRuntime,
    actionSetExam,
    actionSetExamResult,
    _actionSetSectionIndex,
    actionSetPageIndex,
    actionSetSectionFinish,
    actionSetSectionUsedTime,
    _actionSetQuestionAnswer,
    _actionSetQuestionResult,
    _actionSetQuestionFlag,
    actionSetExamStage,
    actionSetExamShowResult,
    _actionSetPassage,
    _actionSetInput,
    _actionSetInquiry,
    _actionSetAnalysis,
    _actionAddQuestionUsedTime,
    actionSetReviewIndex,
    _actionSetConnectWordCraft,
    actionSetExamIDOrigin,
    actionSetFromURL,
    actionSetExamQuestion,
    actionSetCreateDialog,
} = examRuntimeSlice.actions;

export default examRuntimeSlice.reducer;
