import { RateScore } from "./Count";
import { ExerciseSummary, UserDaily } from "./Daily";

export class UserSummary {
    simulate: Simulate = new Simulate();
    exercise: ExerciseSummary = new ExerciseSummary();
    question: Question = new Question();
    activity: Activity = new Activity();
    is_backend_data: boolean = false;
}

export class HomeSummary {
    simulate: Simulate = new Simulate();
    question: Question = new Question();
}

export class Simulate {
    used_count: number = 0;
    all_avg_score: SimulateAvgScore = new SimulateAvgScore();
}

export class SimulateAvgScore {
    scope: number = 0;
    v: RateScore = new RateScore();
    q: RateScore = new RateScore();
}

export class Question {
    total: number = 0;
    wrongs_count: number = 0;
}

export class ExerciseProgress {
    big: Progress = new Progress();
    pick: Progress = new Progress();
    best: Progress = new Progress();
}

export class Progress {
    val1: number = 0;
    val2: number = 0;
    val3: number = 0;
}

export class Activity {}

export class GetSummaryRsp {
    user_summary: UserSummary = new UserSummary();
}

export class GetDailyRsp {
    user_daily: UserDaily = new UserDaily();
}

export class GetMonthActivityRsp {
    days: number[] = [];
}

export class SignInRsp {
    user_daily: UserDaily = new UserDaily();
    calendar_days: number[] = [];
}
