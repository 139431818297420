// 自定义Enum类型, 支持枚举值和中文展示
// 兼容SelectIndexItem
export class EnumItem {
    // 优先用此属性
    value: number = 0;
    name: string = "";

    // 兼容旧格式的属性
    key: number = 0;
    val: number = 0;
    str: string = "";

    constructor(val: number, str: string) {
        this.key = val;
        this.value = val;
        this.val = val;

        this.name = str;
        this.str = str;
    }
}

export function Make_GetEnumName(items: EnumItem[]) {
    return (val: number): string => {
        for (let i = 0; i < items.length; i++) {
            if (items[i].value === val) {
                return items[i].name;
            }
        }
        return "";
    };
}

export function Make_GetEnumItem(items: EnumItem[]) {
    return (val: number): EnumItem => {
        for (let i = 0; i < items.length; i++) {
            if (items[i].value === val) {
                return items[i];
            }
        }
        return new EnumItem(0, "");
    };
}

export interface EnumDisplay {
    value: number;
    name: string;
}

export interface EnumStringDisplay {
    value: string;
    name: string;
}
