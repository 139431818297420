import { AnyAction, PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AuthData } from "../../model/TokenAuthData";
import { User, UserInfoBank } from "../../model/UserData";
import { Authority } from "../../model/permission/auth";

export function isUserAction(action: AnyAction): boolean {
    return action.type.startsWith("user");
}

function getInitState() {
    return {
        data: new User(),
        login: false,
        auth: {
            user_id: "",
            user_name: "",
            exp: new Date(0),
        },
        editMode: false,
        highlightMode: false,
        showAnalysis: false,
        selectMode: false,
        adminMode: false,
        showTranslate: false,
        infoBank: new UserInfoBank(),
    };
}

export const userSlice = createSlice({
    name: "user",
    initialState: getInitState(),
    reducers: {
        actionUserLogin: (state, action: PayloadAction<User>) => {
            // clean state
            state.editMode = false;
            state.highlightMode = false;
            state.showAnalysis = false;
            state.selectMode = false;
            state.adminMode = false;
            state.showTranslate = false;

            // set data
            state.data = action.payload;
            state.login = true;
            if (state.data.is_admin) {
                state.adminMode = true;
            }
        },
        actionSetUserData: (state, action: PayloadAction<User>) => {
            state.data = action.payload;
        },
        actionUpdateUserAuth: (state, action: PayloadAction<AuthData>) => {
            state.auth = action.payload;
        },
        actionSetEditMode: (state, action: PayloadAction<boolean>) => {
            state.editMode = action.payload;
        },
        actionSetHighlightMode: (state, action: PayloadAction<boolean>) => {
            state.highlightMode = action.payload;
        },

        actionSetLogin: (state, action: PayloadAction<boolean>) => {
            state.login = action.payload;
        },
        actionSetShowAnalysis: (state, action: PayloadAction<boolean>) => {
            state.showAnalysis = action.payload;
        },
        actionSetUserAuth: (state, action: PayloadAction<Authority>) => {
            state.data.authority = action.payload;
        },
        actionSetSelectMode: (state, action: PayloadAction<boolean>) => {
            state.selectMode = action.payload;
        },
        actionSetAdminMode: (state, action: PayloadAction<boolean>) => {
            if (!state.data.is_admin) {
                return;
            }
            if (!action.payload) {
                state.editMode = false;
                state.highlightMode = false;
            }
            state.adminMode = action.payload;
        },
        actionSetShowTranslate: (state, action: PayloadAction<boolean>) => {
            state.showTranslate = action.payload;
        },
        actionSetUserInfoBank: (state, action: PayloadAction<UserInfoBank>) => {
            state.infoBank = action.payload;
            state.infoBank.isBackend = true;
        },
    },
});

export const {
    actionUpdateUserAuth,
    actionUserLogin,
    actionSetEditMode,
    actionSetHighlightMode,
    actionSetLogin,
    actionSetShowAnalysis,
    actionSetUserAuth,
    actionSetUserData,
    actionSetSelectMode,
    actionSetAdminMode,
    actionSetShowTranslate,
    actionSetUserInfoBank,
} = userSlice.actions;

export default userSlice.reducer;
