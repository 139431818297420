export enum RichTextKind {
    HTMLText = 0,
    PureText = 1,
}

export class RichText {
    type: number = 0;
    html_text: string = "";
    pure_text: string = "";
    contains_mathml: boolean = false;
    contains_rep: boolean = false;
}

export class Translate {
    cn: string = "";
    contains_rep: boolean = false;
}
