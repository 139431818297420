import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { actionSetVIPDialog } from "../../store/dialog/reducer";
import { selectVIPDialog } from "../../store/dialog/selector";
import VIPTableImg from "./asset/vip_table.jpg";
import { BuySVIPButton } from "./component/BuySVIPButton";
import { BuyVIPButton } from "./component/BuyVIPButton";
import { RightsButton } from "./component/RightsButton";

export function VIPDialog() {
    const dispatch = useDispatch();

    const open = useSelector(selectVIPDialog);

    const onClose = () => {
        dispatch(actionSetVIPDialog(false));
    };
    const onConfirm = () => {
        dispatch(actionSetVIPDialog(false));
    };
    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
            <DialogTitle>获取会员权益</DialogTitle>
            <DialogContent>
                <Box sx={{ mt: "20px", position: "relative" }}>
                    <img src={VIPTableImg} width="800px" alt="会员权益表" />
                    <Box sx={{ position: "absolute", top: "200px", left: "300px" }}>
                        <BuyVIPButton onClick={onClose} />
                    </Box>
                    <Box sx={{ position: "absolute", top: "200px", left: "580px" }}>
                        <BuySVIPButton onClick={onClose} />
                    </Box>
                    <Box sx={{ position: "absolute", top: "430px", left: "35px" }}>
                        <RightsButton />
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onConfirm} autoFocus>
                    关闭
                </Button>
            </DialogActions>
        </Dialog>
    );
}
