import { EnumItem } from "../../Enum";

export class CraftSearchOption {
    filter_collected: boolean = false;
    filter_noted: boolean = false;
    filter_wrong: boolean = false;
    filter_in_higher: boolean = false;
    filter_card_kind: number = 0;
}

export enum FilterCardKind {
    Finish = 1,
    Collect = 2,
}

export const FilterCardItems = [
    new EnumItem(FilterCardKind.Finish, "背过"),
    new EnumItem(FilterCardKind.Collect, "收藏"),
];
