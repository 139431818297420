export class ExamInterpret {
    question_kinds_correct: VQInterpret = new VQInterpret();
    knowledge_correct: KnowledgeInterpret = new KnowledgeInterpret();
    sections_diff: Interpret = new Interpret();
    sections_time_cost: Interpret = new Interpret();
    question_kinds_time_cost: Interpret = new Interpret();
    diffs_time_cost: Interpret = new Interpret();
}

export class GrowthInterpret {
    question_kinds_correct: VQInterpret = new VQInterpret();
    question_kinds_time_cost: Interpret = new Interpret();
    knowledge_correct: KnowledgeInterpret = new KnowledgeInterpret();
}

export class VQInterpret {
    v: Interpret = new Interpret();
    q: Interpret = new Interpret();
}

export class KnowledgeInterpret {
    math_correct: Interpret = new Interpret();
    reading_correct: Interpret = new Interpret();
}

export class Interpret {
    items: InterpretItem[] = [];
}

export class InterpretItem {
    text: string = "";
    link_kind: number = 0;
}
