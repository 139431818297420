import { LogDebug } from "../bblib/tools/log";
import { TOKEN_KEY } from "../model/TokenAuthData";
import {
    GetUserInfosRsp,
    LoginRsp,
    RegisterRsp,
    ResetPasswordRsp,
    ResumeLoginRsp,
    User,
    UserInfoBank,
} from "../model/UserData";
import { requestBackend } from "./common";

interface AccountLoginProps {
    account: string;
    isEmail: boolean;
    password: string;
    afterSuccess: (u: User) => void;
    dispatch: any;
}

export const AccountLogin = (props: AccountLoginProps) => {
    const { account, isEmail, password, afterSuccess, dispatch } = props;
    localStorage.removeItem(TOKEN_KEY);
    requestBackend({
        uri: "/api/user/login",
        req_data: {
            login_type: isEmail ? 2 : 0,
            account: isEmail ? "" : account,
            email: isEmail ? account : "",
            password: password,
        },
        handleRsp: (rsp_json: any) => {
            let rsp = new LoginRsp();
            Object.assign(rsp, rsp_json);
            LogDebug("account_login_rsp", rsp);
            localStorage.setItem(TOKEN_KEY, rsp.login_token);
            afterSuccess(rsp.user);
        },
        errTitle: "登录失败",
        dispatch: dispatch,
    });
};

interface UserRegisterProps {
    account: string;
    password: string;
    email: string;
    code: string;
    nick: string;
    afterSuccess: (u: User) => void;
    dispatch: any;
}

export const UserRegister = (props: UserRegisterProps) => {
    const { account, password, email, code, afterSuccess, dispatch, nick } = props;
    localStorage.removeItem(TOKEN_KEY);
    requestBackend({
        uri: "/api/user/register",
        req_data: {
            email: email,
            verification_code: code,
            account: account,
            password: password,
            nick_name: nick,
        },
        handleRsp: (rsp_json: any) => {
            let rsp = new RegisterRsp();
            Object.assign(rsp, rsp_json);
            LogDebug("register_rsp", rsp);
            localStorage.setItem(TOKEN_KEY, rsp.login_token);
            afterSuccess(rsp.user);
        },
        errTitle: "注册失败",
        dispatch: dispatch,
        backdrop: true,
    });
};

interface ResumeLoginProps {
    afterSuccess: (user: User) => void;
    dispatch: any;
}

export const ResumeLogin = (props: ResumeLoginProps) => {
    const { afterSuccess, dispatch } = props;
    return requestBackend({
        uri: "/api/user/resume_login",
        req_data: {},
        handleRsp: (rsp_json: any) => {
            let rsp = new ResumeLoginRsp();
            Object.assign(rsp, rsp_json);
            afterSuccess(rsp.user);
            LogDebug("ResumeLoginRsp", rsp);
        },
        errTitle: "恢复登录失败，请重新登录",
        dispatch,
        backdrop: true,
    });
};

interface GetUserInfosProps {
    afterSuccess: (b: UserInfoBank) => void;
    dispatch: any;
}

export const GetUserInfos = (props: GetUserInfosProps) => {
    const { afterSuccess, dispatch } = props;
    const req_data = {};
    requestBackend({
        uri: "/api/user/get_infos",
        req_data: req_data,
        handleRsp: (rsp_json: any) => {
            let rsp = new GetUserInfosRsp();
            Object.assign(rsp, rsp_json);
            LogDebug("GetUserInfosRsp", rsp);
            afterSuccess({ infos: rsp.infos, isBackend: true });
        },
        errTitle: "获取用户信息失败",
        dispatch,
    });
};

interface ResetPasswordProps {
    userID: string;
    password: string;
    afterSuccess: () => void;
    dispatch: any;
}

export const ResetPassword = (props: ResetPasswordProps) => {
    const { afterSuccess, dispatch, userID, password } = props;
    const req_data = {
        user_id: userID,
        password: password,
    };
    requestBackend({
        uri: "/api/user/reset_password",
        req_data: req_data,
        handleRsp: (rsp_json: any) => {
            let rsp = new ResetPasswordRsp();
            Object.assign(rsp, rsp_json);
            LogDebug("ResetPasswordRsp", rsp);
            afterSuccess();
        },
        errTitle: "重置密码失败",
        dispatch,
    });
};
