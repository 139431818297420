import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
} from "@mui/material";
import { PropsWithChildren, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { methodGetTrade, methodInitPay } from "../../api/apipay/api";
import { InitPayRsp } from "../../api/apipay/pay";
import { SwitchContent } from "../../bblib/component/SwitchContent";
import { sleep, useInterval } from "../../bblib/tools/time";
import { RefreshPage } from "../../bblib/tools/url";
import { Trade, TradeStatus, VIPTime } from "../../model/security/Pay";
import { TradeKind } from "../../model/security/TradeKind";
import { User } from "../../model/UserData";
import { actionSetPayDialog } from "../../store/dialog/reducer";
import { selectPayDialog } from "../../store/dialog/selector";
import { useAppDispatch } from "../../store/hooks";
import { selectUser } from "../../store/user/selector";
import flashPayImg from "./asset/flash_pay.jpg";
import svipPayImg from "./asset/svip_pay.jpg";
import vipPayImg from "./asset/vip_pay.jpg";

export function VIPPayDialog() {
    const dispatch = useAppDispatch();

    const param = useSelector(selectPayDialog);

    const onClose = () => {
        dispatch(actionSetPayDialog({ show: false, kind: 0 }));
    };

    return (
        <Dialog open={param.show} onClose={onClose} fullWidth maxWidth="md">
            <DialogTitle>
                扫码支付开通&nbsp;
                {param.kind === TradeKind.VIP && <>BB大会员</>}
                {param.kind === TradeKind.SVIP && <>BB超级大会员</>}
            </DialogTitle>

            <DialogContent>
                <SwitchContent
                    titles={["7天", "1月", "2月", "3月"]}
                    contents={[
                        <VIPPay kind={param.kind} vipTime={VIPTime.Day7} />,
                        <VIPPay kind={param.kind} vipTime={VIPTime.Month1} />,
                        <VIPPay kind={param.kind} vipTime={VIPTime.Month2} />,
                        <VIPPay kind={param.kind} vipTime={VIPTime.Month3} />,
                    ]}
                    contentMargin="0 0 0 0"
                    defaultIndex={1}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>取消</Button>
            </DialogActions>
        </Dialog>
    );
}

interface VIPPayProps {
    kind: TradeKind;
    vipTime: VIPTime;
}

function VIPPay(props: VIPPayProps) {
    const { kind, vipTime } = props;
    return (
        <Pay
            kind={kind}
            param={vipTime.toString()}
            onSuccess={() => {
                sleep(1000).then(() => {
                    RefreshPage();
                });
            }}
            successMsg="支付成功，即将自动刷新网页（若网页长时间没有刷新，您可以手动刷新）"
        />
    );
}

interface PayProps {
    kind: TradeKind;
    param: string;
    onSuccess: (trade: Trade, user: User) => void;
    successMsg: string;
    hintDiv?: any;
}
export function Pay(props: PayProps) {
    const dispatch = useAppDispatch();

    const { kind, param, successMsg, onSuccess, hintDiv } = props;

    const [rsp, setRsp] = useState(new InitPayRsp());
    const [status, setStatus] = useState(TradeStatus.None);

    const user = useSelector(selectUser);

    useEffect(() => {
        if (rsp.trade.user_id === user.id && rsp.trade.kind === kind && rsp.trade.param === param) {
            return;
        }
        if (kind === TradeKind.None) {
            return;
        }
        methodInitPay(dispatch, {
            user_id: user.id,
            kind,
            param,
        }).then((rsp) => {
            setRsp(rsp);
        });
    }, [user.id, dispatch, kind, param]);

    useInterval(() => {
        if (rsp.trade.id.length === 0) {
            return;
        }
        methodGetTrade(dispatch, {
            id: rsp.trade.id,
        }).then((rsp) => {
            const trade = rsp.trade;
            setStatus(trade.status);
            if (trade.status === TradeStatus.ApplySuccess) {
                if (onSuccess) {
                    onSuccess(rsp.trade, rsp.user);
                }
            }
        });
    }, 2000);

    return (
        <>
            {status <= TradeStatus.PayInit && (
                <>
                    <Stack sx={{ alignItems: "center", position: "relative" }}>
                        <BackgroundImage kind={kind}>
                            {rsp.qrcode_url.length > 0 ? (
                                <img
                                    src={rsp.qrcode_url}
                                    alt="微信付款二维码"
                                    width="270px"
                                    height="270px"
                                />
                            ) : (
                                <Box
                                    sx={{
                                        width: "270px",
                                        height: "270px",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <CircularProgress />
                                    <Box sx={{ mt: "30px" }}>支付二维码加载中，请稍后</Box>
                                    <Box sx={{ fontSize: 10 }}>
                                        若长时间（30s）未显示，可关闭窗口重试
                                    </Box>
                                </Box>
                            )}
                        </BackgroundImage>
                        {rsp.price > 0 && (
                            <Box sx={{ fontSize: 20, mt: "-10px" }}>
                                总计&nbsp;<b>{rsp.price}</b>&nbsp;元，请使用<b>微信</b>支付
                            </Box>
                        )}
                        {hintDiv}
                    </Stack>
                </>
            )}
            {status >= TradeStatus.PaySuccess && (
                <Stack sx={{ alignItems: "center", justifyContent: "center" }}>
                    <Box
                        sx={{
                            width: "300px",
                            height: "300px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <CheckCircleIcon sx={{ color: "green", fontSize: 60 }} />
                    </Box>
                    <p>{successMsg}</p>
                </Stack>
            )}
        </>
    );
}

function BackgroundImage(props: PropsWithChildren<{ kind: TradeKind }>) {
    const { children, kind } = props;

    const getRelative = () => {
        if (kind === TradeKind.VIP) {
            return ["377px", "220px", vipPayImg, "大会员底图"];
        }
        if (kind === TradeKind.SVIP) {
            return ["377px", "225px", svipPayImg, "超级大会员底图"];
        }
        if (kind === TradeKind.FlashCoin) {
            return ["323px", "215px", flashPayImg, "闪卡支付底图"];
        }
        return ["377px", "220px", "", "底图"];
    };
    const [top, left, imgSrc, alt] = getRelative();
    return (
        <Box>
            <Box sx={{ position: "relative" }}>
                <img src={imgSrc} alt={alt} width="700px" />
                <Box sx={{ position: "absolute", top: top, left: left, zIndex: 10 }}>
                    {children}
                </Box>
            </Box>
        </Box>
    );
}
