export class RateCount {
    value: number = 0;
    total: number = 0;
    remain: number = 0;
    rate_str: string = "0%";
    rate: number = 0;
}

export class RateScore {
    a: number = 0;
    b: number = 0;
    rate: number = 0;
    rate_str: string = "0%";
}

export class RankRateCount {
    rate: RateCount = new RateCount();
    median_rank: RateCount = new RateCount();
}
