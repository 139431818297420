import { Box, Button, TextField } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { UploadImageRsp } from "../../../api/apiquestion/upload";
import { AdminBox } from "../../../bblib/component/AdminBox";
import { BBDialog } from "../../../bblib/component/dialog/BBDialog";
import { AlertError, AlertSuccess } from "../../../bblib/component/Snackbar";
import { LogDebug } from "../../../bblib/tools/log";
import { actionSetUploadDialog } from "../../../store/dialog/reducer";
import { selectUploadDialog } from "../../../store/dialog/selector";
import { useAppDispatch } from "../../../store/hooks";
import { selectQuestionID } from "../../../store/question/selector";
import { selectEditMode } from "../../../store/user/selector";

export function UploadDialog() {
    const dispatch = useAppDispatch();
    const open = useSelector(selectUploadDialog);
    const setOpen = (b: boolean) => {
        dispatch(actionSetUploadDialog(b));
    };
    return (
        <BBDialog
            confirmTitle="关闭"
            open={open}
            setOpen={setOpen}
            content={<ImageUpload />}
            noCancel
        />
    );
}

export function UploadButton() {
    const dispatch = useAppDispatch();
    const editMode = useSelector(selectEditMode);
    return (
        <>
            {editMode && (
                <AdminBox>
                    <Button
                        onClick={() => {
                            dispatch(actionSetUploadDialog(true));
                        }}
                    >
                        上传图片
                    </Button>
                </AdminBox>
            )}
        </>
    );
}

export function ImageUpload() {
    const questionID = useSelector(selectQuestionID);

    const [file, setFile] = useState<File | null>(null);

    const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            setFile(event.target.files[0]);
        }
    };

    const onFileUpload = async () => {
        if (questionID.length === 0) {
            AlertError("题目为空");
            return;
        }
        if (file === null) {
            AlertError("请选择图片");
            return;
        }
        if (file.name.length === 0) {
            AlertError("文件名为空");
            return;
        }
        const formData = new FormData();
        formData.append("file", file);
        formData.append("qid", questionID);
        fetch("/api/question/upload_image", { method: "POST", body: formData })
            .then((rspRaw) => {
                if (rspRaw.ok) {
                    return rspRaw.json();
                }
                LogDebug("rspRaw err", rspRaw);
                throw Error(`code ${rspRaw.status} msg ${rspRaw.statusText}`);
            })
            .then((j) => {
                const rsp = j as UploadImageRsp;
                setCode(rsp.img_html);
                navigator.clipboard.writeText(rsp.img_html);
                AlertSuccess("上传成功, 代码已复制到剪贴板");
            })
            .catch((err) => {
                LogDebug("upload err", err);
                AlertError(`上传失败 ${err}`);
            });
    };

    const [code, setCode] = useState("");
    return (
        <>
            <h4>题目: {questionID}</h4>
            <input type="file" onChange={onFileChange} />
            <Button onClick={onFileUpload} disabled={questionID.length === 0 || file === null}>
                上传
            </Button>
            <Box sx={{ height: "30px" }} />
            <hr />
            <TextField
                sx={{ width: "500px" }}
                value={code}
                placeholder="上传后复制此处代码到题目"
                multiline
            />
        </>
    );
}
