import { EnumItem, Make_GetEnumName } from "../Enum";

export enum BuyFlashCoinKind {
    KindCoin50 = 1,
    KindCoin100 = 2,
    KindCoin200 = 3,
}
const BuyFlashCoinItems = [
    new EnumItem(BuyFlashCoinKind.KindCoin50, "50题"),
    new EnumItem(BuyFlashCoinKind.KindCoin100, "100题"),
    new EnumItem(BuyFlashCoinKind.KindCoin200, "200题"),
];

export const BuyFlashCoinMap = Make_GetEnumName(BuyFlashCoinItems);
