interface GlobalConfig {
    auto_resume_login: boolean;
    api_version: string;
}

export default function GetGlobalConfig(): GlobalConfig {
    return {
        auto_resume_login: true,
        api_version: process.env.REACT_APP_API_VERSION ? process.env.REACT_APP_API_VERSION : "",
    };
}
