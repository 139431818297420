import { RateCount, RateScore } from "../Count";

// 正确率和排名
export class CorrectRateRank {
    user_correct_rate: RateCount = new RateCount(); // 当前用户的正确率
    median_correct_rate: number = 0; // 所有用户正确率的中位数
    avg_correct_rate: number = 0;
    rank: RateCount = new RateCount(); // 排名
    hide: boolean = false;
}

export class ProficiencyRank {
    level: number = 0;
    score: number = 0;
    rank: RateScore = new RateScore();
}

export class KnowCorrectRateRank {
    name: string = "";
    know_id: string = "";
    correct: CorrectRateRank = new CorrectRateRank();
    hide: boolean = false;
}
