import { ButtonProps } from "@mui/material";
import { EnumItem } from "./Enum";

export class Tag {
    id: string = "";
    label: string = "";
    url: string = "";
    hide: boolean = false;
    kind: number = 0;
    extra: Map<string, string> = new Map();
    constructor(label: string = "") {
        this.label = label;
    }
}

export enum TagKind {
    None = 0,
    Math = 1,
    Reading = 2,
    Character = 3,
    Synonyms = 4,
    Antonyms = 5,
}

export const TagKindItems = [
    new EnumItem(TagKind.None, "无"),
    new EnumItem(TagKind.Math, "数学"),
    new EnumItem(TagKind.Reading, "阅读"),
    new EnumItem(TagKind.Character, "字库"),
    new EnumItem(TagKind.Synonyms, "取同"),
    new EnumItem(TagKind.Antonyms, "取反"),
];

export function GetTagKindButtonColor(kind: TagKind): ButtonProps["color"] {
    switch (kind) {
        case TagKind.None:
            return "bblabelgray";
        case TagKind.Math:
            return "bblabelred";
        case TagKind.Reading:
            return "bblabelgray";
        case TagKind.Character:
            return "bblabelyellow";
        case TagKind.Synonyms:
            return "bblabelblue";
        case TagKind.Antonyms:
            return "bblabelgreen";
        default:
            return "bblabelgray";
    }
}

export class BackendTagBank {
    tags: Tag[] = [new Tag()];
}

export class TagBank {
    is_backend_data: boolean = false;
    tagMap: Map<string, Tag> = new Map(); // key:id
}
export class GetTagsRsp {
    bank: BackendTagBank = new BackendTagBank();
}

export class UpdateTagRsp {
    tag: Tag = new Tag();
}

export class DeleteTagRsp {}

export class MergeTagRsp {}

export class ReadTagJsonRsp {
    content: string = "";
}
