import { useEffect, useRef } from "react";

export function useInterval(callback: any, delay: any) {
    const savedCallback = useRef<any>();

    // 保存新回调
    useEffect(() => {
        savedCallback.current = callback;
    });

    // 建立 interval
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}

export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));
