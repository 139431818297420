import { Button } from "@mui/material";
import { enqueueSnackbar, closeSnackbar, SnackbarKey } from "notistack";
import { Fragment } from "react";

export function AlertSuccess(msg: string, makeButton?: (key: SnackbarKey) => any) {
    enqueueSnackbar(msg, {
        variant: "success",
        anchorOrigin: {
            vertical: "top",
            horizontal: "center",
        },
        action: (key) => (
            <Fragment>
                {makeButton && makeButton(key)}
                <Button
                    variant="text"
                    size="small"
                    color="inherit"
                    onClick={() => {
                        closeSnackbar(key);
                    }}
                >
                    Close
                </Button>
            </Fragment>
        ),
    });
}

export function AlertError(msg: string, makeButton?: (key: SnackbarKey) => any) {
    enqueueSnackbar(msg, {
        variant: "error",
        anchorOrigin: {
            vertical: "top",
            horizontal: "center",
        },
        action: (key) => (
            <Fragment>
                {makeButton && makeButton(key)}
                <Button
                    variant="text"
                    size="small"
                    color="inherit"
                    onClick={() => {
                        closeSnackbar(key);
                    }}
                >
                    Close
                </Button>
            </Fragment>
        ),
    });
}

export function AlertWarning(msg: string, makeButton?: (key: SnackbarKey) => any) {
    enqueueSnackbar(msg, {
        variant: "warning",
        anchorOrigin: {
            vertical: "top",
            horizontal: "center",
        },
        action: (key) => (
            <Fragment>
                {makeButton && makeButton(key)}
                <Button
                    variant="text"
                    size="small"
                    color="inherit"
                    onClick={() => {
                        closeSnackbar(key);
                    }}
                >
                    Close
                </Button>
            </Fragment>
        ),
    });
}
