import { PaletteColorOptions } from "@mui/material";
import { lightenColor } from "./color_tool";

// 基色
const baseColors = {
    white: "#FFFFFF",
    success: "rgb(122,157,84)",
    wrong: "#B31312", // 错误红
    admin: "#0000FF", // 管理蓝
};

export const Colors = {
    // 红/棕
    primary: "rgb(191,72,62)",
    primarya: "rgba(191,72,62,.8)",
    primarya2: "rgba(191,72,62,.3)",
    // primaryb: lightenColor("rgb(191,72,62)", 20),
    // primaryb2: lightenColor("rgb(191,72,62)", 40),
    bbred: "rgb(165,74,55)", // 官方棕
    bbreda4: "rgba(165,74,55,0.8)",
    bbofficial: "rgb(165,74,55)",
    bbreda0: "rgba(165,74,55,0.3)",
    bbreda: "rgba(165,74,55,0.2)",
    bbred3: "rgba(191,72,62,.1)",
    wrong: baseColors["wrong"],
    error: baseColors["wrong"],

    // 绿
    success: baseColors["success"],
    bbsuccess: baseColors["success"],
    bbcontinue: baseColors["success"],
    correct: baseColors["success"],
    green: "rgb(60, 179, 113)",

    // 白
    bbbackground: baseColors["white"],
    background2: "rgb(240,240,240)",

    // 灰
    gray: "rgb(112,112,112)",
    gray2: "rgb(150,150,150)",
    gray1_5: "rgb(230,230,230)",
    bbgray3: "rgb(250,250,250)",
    bbgray4: "rgb(100,100,100)",
    bbgraya: "rgba(112,112,112,0.5)",
    bbgraya2: "rgba(112,112,112,0.2)",
    bbgraya3: "rgba(112,112,112,0.1)",
    bbdisable: "rgb(112,112,112)",

    // 蓝色
    blue: "rgb(30, 144, 255)",

    // 管理蓝
    admin: baseColors["admin"],
    bbadmin: lightenColor(baseColors["admin"], 10),
    op: "#40E0D0",

    bbyellow2: "rgb(243,231,143)",

    // 标签色
    bblabel: "rgb(112,112,112)",
    bblabelurl: "rgba(191,72,62,.8)",
    bblabelred: "rgba(191,72,62,.8)",
    bblabelgray: "rgb(112,112,112)",
    bblabelblue: lightenColor("#0000FF", 30),
    bblabelyellow: lightenColor("rgb(255,199,115)", 15),
    bblabelgreen: "rgba(41,223,8,0.15)",

    kind1: "rgba(43,42,76,0.1)",
    kind2: "rgba(255,199,115,0.1)",
    kind3: "rgba(122,157,84,0.1)",
    kind4: "rgba(165,74,55,0.05)",
    kind5: "rgb(170,108,173,0.1)",

    bbinput: "rgb(112,112,112)",
    bbdebug: "#2B2A4C",
    bbreview: "rgb(165,74,55)",
    bbrestart: "#D79416",
    bbissue: "#FFFFFF",
    bblabelword: lightenColor("#0000FF", 26),
    bbpurple: "rgb(170,108,173)",
    bbyellow: "rgb(116,94,28)",
    report: "rgb(79,113,190)",

    gold: lightenColor("#FFD700", -4),
    silver: "rgb(192, 192, 192)",
    bronze: lightenColor("#D2691E", -10),
    pink: "rgb(255, 182, 193)",

    resultKind1: "rgba(165,74,55,0.8)",
    resultKind2: "rgb(150,150,150)",
    resultKind3: "rgb(255, 182, 193)",
};
export type ColorsType = typeof Colors;

export const ColorPrimary = Colors["primary"];
export const ColorBBHighTitle = Colors["primary"];
export const ColorPrimaryA = Colors["primarya"];
export const ColorPrimaryA2 = Colors["primarya2"];
export const ColorBBRedA3 = Colors["bbred3"];
export const ColorSuccess = Colors["bbsuccess"];
export const ColorError = Colors["error"];
export const ColorBBTailorTitle = Colors["bbpurple"];
export const ColorBBGray = Colors["gray"]; // 搜索灰色
export const ColorAdminA = Colors["bbadmin"];
export const ColorAdmin = Colors["admin"];
export const ColorBBReportBlue = Colors["report"];
export const ColorBBGray1_5 = Colors["gray1_5"]; // 搜索灰色
export const ColorBBGray3 = Colors["bbgray3"];
export const ColorBBGray4 = Colors["bbgray4"];
export const ColorBBGrayA = Colors["bbgraya"];
export const ColorBBGrayA2 = Colors["bbgraya2"];
export const ColorBBGrayA3 = Colors["bbgraya3"];
export const ColorBBDisable = Colors["bbgraya"];
export const ColorBBKind1 = Colors["kind1"];
export const ColorBBKind2 = Colors["kind2"];
export const ColorBBKind3 = Colors["kind3"];
export const ColorBBKind4 = Colors["kind4"];
export const ColorBBKind5 = Colors["kind5"];
export const ColorOfficialRed = Colors["bbred"];
export const ColorOfficialRedA0 = Colors["bbreda0"]; // 官方棕
export const ColorOfficialRedA = Colors["bbreda"]; // 官方棕
export const ColorBackGround2 = Colors["background2"];

type CustomPaletteType = {
    [key in keyof ColorsType]: PaletteColorOptions;
};
export interface CustomPalette extends CustomPaletteType {}

type ColorOverridesType = {
    [key in keyof ColorsType]: true;
};
export interface ColorOverrides extends ColorOverridesType {}
