import { Box, BoxProps } from "@mui/material";
import { useSelector } from "react-redux";
import { selectAdminMode, selectIsOperator } from "../../store/user/selector";
import { Colors } from "../../tools/color";

export function AdminBox(props: BoxProps) {
    const { sx, children, ...pp } = props;
    const adminMode = useSelector(selectAdminMode);
    return (
        <Box sx={{ color: Colors["admin"], ...sx }} {...pp}>
            {adminMode && children}
        </Box>
    );
}

export function AdminCheck(props: { yes: any; no: any }) {
    const { yes, no } = props;
    const adminMode = useSelector(selectAdminMode);
    return <>{adminMode ? yes : no}</>;
}

export function OpBox(props: BoxProps) {
    const { sx, children, ...pp } = props;
    const isOp = useSelector(selectIsOperator);
    return (
        <Box sx={{ color: Colors["op"], ...sx }} {...pp}>
            {isOp && children}
        </Box>
    );
}
