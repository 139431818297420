import { Button, ButtonProps } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { SVIPCrown } from "../../../bblib/component/svip/Crown";
import { RoleKind } from "../../../model/Role";
import { TradeKind } from "../../../model/security/TradeKind";
import { actionSetPayDialog } from "../../../store/dialog/reducer";
import { selectAuth, selectLogin } from "../../../store/user/selector";

export function BuySVIPButton(props: { onClick?: () => void; size?: ButtonProps["size"] }) {
    const { onClick, size } = props;
    const dispatch = useDispatch();
    const login = useSelector(selectLogin);
    const auth = useSelector(selectAuth);
    // const isFreeze = useSelector(selectIsFreeze);
    return (
        <>
            {login && (
                <>
                    {auth.role_kind < RoleKind.SVIP ? (
                        <Button
                            size={size}
                            onClick={() => {
                                dispatch(actionSetPayDialog({ show: true, kind: TradeKind.SVIP }));
                                if (onClick !== undefined) {
                                    onClick();
                                }
                            }}
                            // disabled={isFreeze}
                        >
                            <SVIPCrown>立刻成为BB超级大会员</SVIPCrown>
                        </Button>
                    ) : (
                        <Button disabled={true}>当前身份: BB超级大会员</Button>
                    )}
                </>
            )}
        </>
    );
}
