import { Badge, BadgeProps } from "@mui/material";
import { PropsWithChildren } from "react";
import { ReactComponent as CrownIconBase } from "./asset/crown.svg";

export function AvatarCrownIcon() {
    return (
        <CrownIconBase
            style={{
                position: "absolute",
                top: 0,
                right: 0,
                width: "18px",
                transform: "rotate(32deg)",
            }}
        />
    );
}

export function ButtonCrownIcon() {
    return (
        <CrownIconBase
            style={{
                position: "absolute",
                top: 0,
                right: 0,
                width: "16px",
                transform: "rotate(15deg)",
            }}
        />
    );
}

export function SVIPCrown(
    props: PropsWithChildren<{ overlap?: BadgeProps["overlap"]; disabled?: boolean }>
) {
    const { children, overlap, disabled } = props;
    return (
        <Badge
            overlap={overlap}
            badgeContent={disabled ? <></> : <ButtonCrownIcon />}
            anchorOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
        >
            {children}
        </Badge>
    );
}
