import { EnumItem, Make_GetEnumName } from "../../Enum";

export enum QuestionCategory {
    None = 0,
    Filling = 1,
    Reading = 2,
    Math = 3,
    Issue = 4,
    Word = 5,
}

export const AllCategoryItems = [
    new EnumItem(QuestionCategory.Filling, "填空"),
    new EnumItem(QuestionCategory.Reading, "阅读"),
    new EnumItem(QuestionCategory.Math, "数学"),
    new EnumItem(QuestionCategory.Issue, "作文"),
    new EnumItem(QuestionCategory.Word, "单词"),
];

export const QuestionCategoryName = Make_GetEnumName(AllCategoryItems);

// 大题库
export const ExerciseBigCategoryItems = [
    new EnumItem(QuestionCategory.Filling, "填空"),
    new EnumItem(QuestionCategory.Reading, "阅读"),
    new EnumItem(QuestionCategory.Math, "数学"),
    new EnumItem(QuestionCategory.Issue, "作文"),
];

// 精选题库
export const ExercisePickCategoryItems = [
    new EnumItem(QuestionCategory.Filling, "填空"),
    new EnumItem(QuestionCategory.Reading, "阅读"),
    new EnumItem(QuestionCategory.Math, "数学"),
];

// 闪卡
export const FlashCategoryItems = [
    new EnumItem(0, "全部"),
    new EnumItem(QuestionCategory.Filling, "填空"),
    new EnumItem(QuestionCategory.Reading, "阅读"),
];

// 搜索
export const SearchCatgoryItems = [
    new EnumItem(0, "全部"),
    new EnumItem(QuestionCategory.Filling, "填空"),
    new EnumItem(QuestionCategory.Reading, "阅读"),
    new EnumItem(QuestionCategory.Math, "数学"),
    new EnumItem(QuestionCategory.Issue, "作文"),
];

export const WrongCategoryItems = [
    new EnumItem(0, "全部"),
    new EnumItem(QuestionCategory.Filling, "填空"),
    new EnumItem(QuestionCategory.Reading, "阅读"),
    new EnumItem(QuestionCategory.Math, "数学"),
];
