import { AlertError } from "../bblib/component/Snackbar";
import { LogDebug, LogError } from "../bblib/tools/log";
import { RefreshPage } from "../bblib/tools/url";
import GetGlobalConfig from "../config/GlobalConfig";
import { TOKEN_KEY } from "../model/TokenAuthData";
import { actionSetShowOutOfLimitDialog, actionSetVIPDialog } from "../store/dialog/reducer";
import { actionAddBackdrop, actionDecBackdrop } from "../store/root/reducer";

export interface API<Req, Rsp> {
    url: string;
}

export function apiRequest<Req, Rsp>(props: {
    api: API<Req, Rsp>;
    req: Req;
    dispatch: any;
    backdrop?: boolean;
    dealErr?: boolean;
}): Promise<Rsp> {
    const { api, req, dispatch, backdrop, dealErr } = props;
    const token = localStorage.getItem(TOKEN_KEY);

    onStart(dispatch, backdrop);

    return new Promise<Rsp>((resolve, reject) => {
        return fetch(api.url, {
            method: "post",
            headers: {
                ...(token ? { Authorization: `Bearer ${token}` } : {}),
                "Content-Type": "application/json",
                "API-Version": GetGlobalConfig().api_version,
            },
            body: JSON.stringify(req),
        })
            .then(dealRspRaw)
            .then(dealRspJson(dispatch, req, resolve, api, backdrop))
            .catch(dealError(dispatch, reject, api, backdrop, dealErr));
    });
}

function dealError<Req, Rsp>(
    dispatch: any,
    reject: (reason?: any) => void,
    api: API<Req, Rsp>,
    backdrop?: boolean,
    dealErr?: boolean
) {
    return (e: any) => {
        LogDebug(`[api] fetch ${api.url} fail:`, e);
        onEnd(dispatch, backdrop);
        AlertError(e.message);
        if (dealErr) {
            reject(e);
        }
        return;
    };
}

type ErrKind =
    | "normal"
    | "limit"
    | "api_version_low"
    | "role_overflow"
    | "day_overflow"
    | "buy_role_exist";

function dealRspJson<Req, Rsp>(
    dispatch: any,
    req: Req,
    resolve: (value: Rsp | PromiseLike<Rsp>) => void,
    api: API<Req, Rsp>,
    backdrop?: boolean
) {
    return (rspJson: any) => {
        // 后端错误码
        if (rspJson["ok"] === true) {
            onEnd(dispatch, backdrop);
            const rsp = rspJson["data"] as Rsp;
            LogDebug(`[api] ${api.url} \nreq`, req, `\nrsp`, rsp);
            resolve(rsp);
            return;
        }
        LogDebug(`[api-error] ${api.url}\nreq`, req, `\nrspJson:`, rspJson);
        const errData = rspJson["err_data"];
        const errKind = errData["kind"] as ErrKind;
        if (errKind === "api_version_low") {
            new Promise((r) => setTimeout(r, 3 * 1000)).then(() => {
                LogDebug("api version too low, refresh page");
                RefreshPage();
            });
            throw Error("网页版本过低, 请刷新（3s后自动刷新）");
        }
        // 触发频控
        if (errKind === "limit") {
            dispatch(actionSetVIPDialog(true));
            // dispatch(actionSetShowPermissionDialog({ show: true, isLimit: true }));
            throw Error("请激活权限");
        }
        // 体验额度用完
        if (errKind === "role_overflow") {
            dispatch(actionSetVIPDialog(true));
            throw Error("体验额度耗尽");
        }
        // 超过权益额度
        if (errKind === "day_overflow") {
            dispatch(
                actionSetShowOutOfLimitDialog({
                    show: true,
                    subject: errData["overflow_subject"],
                    roleName: errData["role_name"],
                })
            );
            throw Error("权益额度耗尽");
        }
        // 已有要购买的会员身份
        if (errKind === "buy_role_exist") {
            new Promise((r) => setTimeout(r, 3 * 1000)).then(() => {
                RefreshPage();
            });
            throw Error("已有会员身份，请刷新网页（3s后自动刷新）");
        }
        // 普通错误: 后台返回了文案
        if (errData["kind"].length > 0) {
            throw Error(errData["show"]);
        }
        // 普通错误: 无文案
        throw Error(errData["detail"]);
    };
}

function dealRspRaw(rspRaw: Response): Promise<any> {
    if (rspRaw.ok) {
        return rspRaw.json();
    }
    LogError("[api] http error, rsp_raw:", rspRaw);
    // http框架错误码
    if (rspRaw.status === 401) {
        localStorage.removeItem(TOKEN_KEY);
        throw Error("请登录");
    }
    if (rspRaw.status === 500 || rspRaw.status === 502) {
        throw Error(`${rspRaw.status} ${rspRaw.statusText}, 请稍后重试`);
    }
    throw Error(`${rspRaw.status} ${rspRaw.statusText}, 请检查网络`);
}

function onStart(dispatch: any, backdrop?: boolean) {
    if (backdrop) {
        dispatch(actionAddBackdrop());
    }
}

function onEnd(dispatch: any, backdrop?: boolean) {
    if (backdrop) {
        dispatch(actionDecBackdrop());
    }
}
