import { createSelector } from "reselect";
import { QuestionInfo } from "../../model/question/Question";
import { RootState } from "../ReduxStore";
import { selectSelectMode } from "../user/selector";

export const selectSearchRuntime = (state: RootState) => state.searchRuntime;

export const selectSidebarRoomIn = (state: RootState) => selectSearchRuntime(state).roomIn;

export const selectSearchQuestion = (state: RootState) => {
    return selectSearchRuntime(state).question;
};

export const selectSidebarQuestionIndex = (state: RootState) => selectSearchRuntime(state).index;
export const selectSidebarShowResult = (state: RootState) => selectSearchRuntime(state).showResult;

export const selectSearchReqData = (state: RootState) => selectSearchRuntime(state).searchReqData;
export const selectSearchRspData = (state: RootState) => selectSearchRuntime(state).searchRspData;
export const selectSearchOption = (state: RootState) => selectSearchReqData(state).search_option;
export const selectSearchPageControl = (state: RootState) =>
    selectSearchRspData(state).page_control;

export const selectSearchQuestionInfos = (state: RootState) =>
    selectSearchRspData(state).question_infos;

export const selectSelectedSearchQuestionInfos = createSelector(
    [selectSearchQuestionInfos, selectSelectMode],
    (infos: QuestionInfo[], selectMode: boolean) => {
        if (selectMode) {
            return infos.filter((info) => info.selected);
        }
        return infos;
    }
);

export const selectSearchQuestionID = (state: RootState) => {
    const sidebar = selectSearchRuntime(state);
    const rsp = selectSearchRspData(state);
    return rsp.question_infos[sidebar.index].id;
};
