import { QuestionKindResult } from "./exam/result/Result";
import { RateCount } from "./statistic/Count";
import { CorrectRateRank, ProficiencyRank } from "./statistic/rank/RankData";

export class OpenReportByBookRsp {
    book_report: BookReport = new BookReport();
    hint: string = "";
}

export class OpenReportRsp {
    book_report: BookReport = new BookReport();
}

export class BookReport {
    id: string = "";
    reports: Report[] = [new Report()];
    is_backend_data: boolean = false;
    progress: ReportProgress = new ReportProgress();
    todo_mark: TODOMark = new TODOMark();
}

export class ReportProgress {
    used_count: number = 0;
    remain_count: number = 0;
    collect_count: number = 0;
    note_count: number = 0;
}
export class Report {
    title: string = "";
    collect: RateCount = new RateCount();
    section: SectionReport = new SectionReport();
    math: MathReport = new MathReport();
    reading: ReadingReport = new ReadingReport();
    word: WordReport = new WordReport();
}

export class SectionReport {
    points: SectionPointReport[] = [];
}

export class SectionPointReport {
    section_type_str: string = "";
    correct_rate: RateCount = new RateCount();
    total_time_used_seconds: number = 0;
    avg_time_used_seconds: number = 0;
    difficulties: SectionPointDifficultyReport[] = [];
}

export class SectionPointDifficultyReport {
    difficulty_str: string = "";
    count: RateCount = new RateCount();
}

// 数学题型掌握程度
export class MathReport {
    points: MathPointReport[] = [new MathPointReport()];
}

export class MathPointReport {
    name: string = "";
    subs: MathSubPointReport[] = [];
    hint: string = "";
}

export class MathSubPointReport {
    name: string = "";
    url: string = "";
    correct: CorrectRateRank = new CorrectRateRank();
    proficiency: ProficiencyRank = new ProficiencyRank();
}

// 阅读题型掌握程度
export class ReadingReport {
    points: ReadingPointReport[] = [];
    type_results: QuestionKindResult[] = [];
}

export class ReadingPointReport {
    name: string = "";
    correct: CorrectRateRank = new CorrectRateRank();
    proficiency: ProficiencyRank = new ProficiencyRank();
}

export class WordReport {
    filling_word_count: RateCount = new RateCount(); // 单、双、三空共考察单词
    six_two_count: RateCount = new RateCount(); // 六选二共考察单词
    correct: CorrectRateRank = new CorrectRateRank(); // 单词掌握程度百分比（实际上统计正确率）
}

// Code generated by transtool(v0.0.3).
export class TODOMark {
    detail: TODOList = new TODOList();
    report: TODOList = new TODOList();
}

// Code generated by transtool(v0.0.3).
export class TODOList {
    items: TODOItem[] = [];
}

// Code generated by transtool(v0.0.3).
export class TODOItem {
    label: string = "";
    done: boolean = false;
}
