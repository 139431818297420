export class Authority {
    role_name: string = "";
    role_kind: number = 0;
    expire_date: string = "";
    flash_coins: number = 0;
    is_freeze: boolean = false;
    is_operator: boolean = false;
    records: Record[] = [];
}

export class Record {
    id: string = "";
    desp: string = "";
    kind: number = 0;
}
