import { AnyAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserInfo } from "../../model/UserData";

export function isManageActoin(action: AnyAction): boolean {
    return action.type.startsWith("manage_runtime");
}

export const manageRuntimeSlice = createSlice({
    name: "manage_runtime",
    initialState: {
        users: [] as UserInfo[],
    },
    reducers: {
        actionSetPermissionUsers: (state, action: PayloadAction<UserInfo[]>) => {
            state.users = action.payload;
        },
    },
});

export const { actionSetPermissionUsers } = manageRuntimeSlice.actions;
