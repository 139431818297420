import { EnumItem } from "../Enum";

export enum TradeKind {
    None = 0,
    VIP = 1,
    SVIP = 2,
    FlashCoin = 3,
    FreeVIP = 4,
    DZ = 5,
    High = 6,
    Other = 7,
    DZCommon = 8,
    DZFullV = 9,
    Higher = 10,
}

export const TradeKindManualVIPItems = [
    new EnumItem(TradeKind.FreeVIP, "普通会员"),
    new EnumItem(TradeKind.VIP, "大会员"),
    new EnumItem(TradeKind.SVIP, "超级大会员"),
    new EnumItem(TradeKind.FlashCoin, "闪卡额度"),
];

export const TradeKindManualUnionPayItems = [
    new EnumItem(TradeKind.DZ, "DZ小范围"),
    new EnumItem(TradeKind.DZCommon, "DZ小范围-通用版"),
    new EnumItem(TradeKind.DZFullV, "DZ小范围-全V"),
    new EnumItem(TradeKind.High, "高新"),
    new EnumItem(TradeKind.Higher, "RAPL"),
    new EnumItem(TradeKind.Other, "其他"),
];
