import { RateCount } from "./Count";

export class ExerciseSummary {
    used_big_questions: RateCount = new RateCount();
    used_pick_questions: RateCount = new RateCount();
    used_pick_flash_questions: RateCount = new RateCount();
}

export class UserDaily {
    exam: ExamDaily = new ExamDaily();
    exercise: ExerciseDaily = new ExerciseDaily();
    question: QuestionDaily = new QuestionDaily();
    signed: boolean = false;
    is_backend_data: boolean = false;
}

export class QuestionDaily {
    used: number = 0;
    used_issue: number = 0;
}

export class ExamDaily {
    used: number = 0;
}
export class ExerciseDaily {
    used_vquestions: number = 0;
    used_qquestions: number = 0;
}
