import { EnumItem, Make_GetEnumName } from "../Enum";
import { Question } from "../question/Question";
import { SectionResult } from "./result/Result";

export enum SectionKind {
    None = 0,
    Verbal = 1,
    Quantitative = 2,
    Issue = 3,
    Word = 4,
}

export enum SectionDifficulty {
    None = 0,
    Easy = 1,
    Medium = 2,
    Hard = 3,
}

export const SectionDifficultyItems = [
    new EnumItem(SectionDifficulty.Easy, "简单"),
    new EnumItem(SectionDifficulty.Medium, "中等"),
    new EnumItem(SectionDifficulty.Hard, "困难"),
] as EnumItem[];

export const SectionDifficultyName = Make_GetEnumName(SectionDifficultyItems);

export class Section {
    index: number = 0;
    name: string = "";
    kind: number = 0;
    kind_str: string = "";
    difficulty: number = 0;
    time_limit_seconds: number = 0;
    action: SectionAction = new SectionAction();
    result: SectionResult = new SectionResult();
    questions: Question[] = [new Question()];
    force_contain_used: boolean = false;
    version_name: string = "";
}

export class SectionAction {
    used_time_seconds: number = 0;
    finished: boolean = false;
}
