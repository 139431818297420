import {
    BackendTagBank,
    DeleteTagRsp,
    GetTagsRsp,
    MergeTagRsp,
    Tag,
    UpdateTagRsp,
} from "../model/TagData";
import { requestBackend } from "./common";

interface GetTagsProps {
    afterSuccess: (bank: BackendTagBank) => void;
    dispatch: any;
}

export function GetTags(props: GetTagsProps) {
    const { dispatch, afterSuccess } = props;
    requestBackend({
        uri: `/api/tag/get_all`,
        req_data: {},
        handleRsp: (rsp_json: any) => {
            let rsp = new GetTagsRsp();
            Object.assign(rsp, rsp_json);
            afterSuccess(rsp.bank);
            return rsp;
        },
        errTitle: "获取标签列表失败",
        dispatch,
    });
}

interface UpdateTagProps {
    tag: Tag;
    afterSuccess: (tag: Tag) => void;
    dispatch: any;
}

export function UpdateTag(props: UpdateTagProps) {
    const { dispatch, afterSuccess, tag } = props;
    requestBackend({
        uri: `/api/tag/update`,
        req_data: {
            id: tag.id,
            tag: tag,
        },
        handleRsp: (rsp_json: any) => {
            let rsp = new UpdateTagRsp();
            Object.assign(rsp, rsp_json);
            afterSuccess(rsp.tag);
            return rsp;
        },
        errTitle: "更新标签信息失败",
        dispatch,
    });
}

interface DeleteTagProps {
    id: string;
    afterSuccess: () => void;
    dispatch: any;
}

export function DeleteTag(props: DeleteTagProps) {
    const { dispatch, afterSuccess, id } = props;
    requestBackend({
        uri: `/api/tag/delete`,
        req_data: {
            id: id,
        },
        handleRsp: (rsp_json: any) => {
            let rsp = new DeleteTagRsp();
            Object.assign(rsp, rsp_json);
            afterSuccess();
            return rsp;
        },
        errTitle: "删除标签失败",
        dispatch,
    });
}

interface MergeTagProps {
    removeID: string;
    mergeID: string;
    afterSuccess: () => void;
    dispatch: any;
}

export function MergeTag(props: MergeTagProps) {
    const { dispatch, afterSuccess, removeID, mergeID } = props;
    requestBackend({
        uri: `/api/tag/merge`,
        req_data: {
            remove_id: removeID,
            merge_id: mergeID,
        },
        handleRsp: (rsp_json: any) => {
            let rsp = new MergeTagRsp();
            Object.assign(rsp, rsp_json);
            afterSuccess();
            return rsp;
        },
        errTitle: "合并标签失败",
        dispatch,
    });
}
