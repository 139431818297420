import { API, apiRequest } from "../api";
import { GetTradeReq, GetTradeRsp, InitPayReq, InitPayRsp } from "./pay";

const apiInitPay: API<InitPayReq, InitPayRsp> = {
    url: "/api/pay/init",
};

export function methodInitPay(dispatch: any, req: InitPayReq) {
    return apiRequest({ api: apiInitPay, req, dispatch });
}

const apiGetTrade: API<GetTradeReq, GetTradeRsp> = {
    url: "/api/trade/get",
};

export function methodGetTrade(dispatch: any, req: GetTradeReq) {
    return apiRequest({ api: apiGetTrade, req, dispatch });
}
