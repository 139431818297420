import { PropsWithChildren } from "react";
import { useSelector } from "react-redux";
import { QuestionCategory } from "../../model/question/data/category";
import { ExamStage } from "../../store/exam/reducer";
import { selectExamStage } from "../../store/exam/selector";
import { selectDisplayMode, selectQuestionCategory } from "../../store/question/selector";
import { DisplayMode } from "../../store/root/reducer";

export function NoCopy(props: PropsWithChildren<{ disabled: boolean }>) {
    const { disabled: disabledRef, children } = props;

    const cat = useSelector(selectQuestionCategory);
    const displayMode = useSelector(selectDisplayMode);
    const examStage = useSelector(selectExamStage);

    const isIssue =
        cat === QuestionCategory.Issue &&
        displayMode === DisplayMode.ExamDispaly &&
        examStage === ExamStage.InExam;

    const disabled = isIssue ? true : disabledRef;

    return (
        <>
            {disabled ? (
                <>{children}</>
            ) : (
                <div
                    onCopy={(e) => {
                        e.preventDefault();
                    }}
                    style={{
                        userSelect: "none",
                        WebkitUserSelect: "none",
                        MozUserSelect: "none",
                        msUserSelect: "none",
                    }}
                >
                    {children}
                </div>
            )}
        </>
    );
}

export function AllowCopy(props: PropsWithChildren<{}>) {
    const { children } = props;
    return (
        <div
            onCopy={undefined}
            style={{
                userSelect: undefined,
                WebkitUserSelect: undefined,
                MozUserSelect: undefined,
                msUserSelect: undefined,
            }}
        >
            {children}
        </div>
    );
}
