const TraceLevel = 4;
const DebugLevel = 3;
const InfoLevel = 2;
const ErrorLevel = 1;
const FatalLevel = 0;

const levelFunc = (): number => {
    switch (process.env.NODE_ENV) {
        case "test":
            return TraceLevel;
        case "development":
            return DebugLevel;
        case "production":
            return InfoLevel;
    }
    return 0;
};

const level: number = levelFunc();

export var LogTrace =
    level >= TraceLevel
        ? function (...data: any[]) {
              console.log("[trace]", ...data);
          }
        : function () {};
export var LogDebug =
    level >= DebugLevel
        ? function (...data: any[]) {
              console.log("[debug]", ...data);
          }
        : function () {};
export var LogInfo =
    level >= InfoLevel
        ? function (...data: any[]) {
              console.log("[info]", ...data);
          }
        : function () {};
export var LogError =
    level >= ErrorLevel
        ? function (...data: any[]) {
              console.log("[error]", ...data);
          }
        : function () {};
export var LogFatal =
    level >= FatalLevel
        ? function (...data: any[]) {
              console.log("[fatal]", ...data);
          }
        : function () {};
