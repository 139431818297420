import { EnumItem } from "./Enum";

export enum RoleKind {
    Stranger = 0,
    Trail = 1,
    FreeVIP = 2,
    VIP = 3,
    SVIP = 4,
}

export const RoleKindItems = [
    new EnumItem(RoleKind.Stranger, "游客"),
    new EnumItem(RoleKind.Trail, "体验用户"),
    new EnumItem(RoleKind.FreeVIP, "普通会员"),
    new EnumItem(RoleKind.VIP, "BB大会员"),
    new EnumItem(RoleKind.SVIP, "BB超级大会员"),
];

export const RoleKindGrantVIPItems = [
    new EnumItem(RoleKind.Trail, "体验用户"),
    new EnumItem(RoleKind.FreeVIP, "普通会员"),
    new EnumItem(RoleKind.VIP, "BB大会员"),
    new EnumItem(RoleKind.SVIP, "BB超级大会员"),
];
