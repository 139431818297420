import { WordInfo } from "../../model/Word";
import { API, apiRequest } from "../api";

const apiGetWords: API<GetWordsReq, GetWordsRsp> = {
    url: "/api/word/get_all",
};

export function methodGetWords(dispatch: any, req: GetWordsReq) {
    return apiRequest({ api: apiGetWords, req, dispatch });
}

const apiMarkSimples: API<MarkSimplesReq, MarkSimplesRsp> = {
    url: "/api/word/mark_simple",
};

export function methodMarkSimples(dispatch: any, req: MarkSimplesReq) {
    return apiRequest({ api: apiMarkSimples, req, dispatch });
}

const apiAddWords: API<AddWordsReq, AddWordsRsp> = {
    url: "/api/word/add_words",
};

export function methodAddWords(dispatch: any, req: AddWordsReq) {
    return apiRequest({ api: apiAddWords, req, dispatch });
}

const apiDeleteWords: API<DeleteWordsReq, DeleteWordsRsp> = {
    url: "/api/word/delete",
};

export function methodDeleteWords(dispatch: any, req: DeleteWordsReq) {
    return apiRequest({ api: apiDeleteWords, req, dispatch });
}

const apiMergeWord: API<MergeWordReq, MergeWordRsp> = {
    url: "/api/word/merge",
};

export function methodMergeWord(dispatch: any, req: MergeWordReq) {
    return apiRequest({ api: apiMergeWord, req, dispatch });
}

interface GetWordsReq {}

interface GetWordsRsp {
    word_infos: WordInfo[];
}

interface MarkSimplesReq {
    ids: string[];
}

interface MarkSimplesRsp {}

interface AddWordsReq {
    text: string;
}

interface AddWordsRsp {
    create_count: number;
}

interface DeleteWordsReq {
    ids: string[];
    force: boolean;
}

interface DeleteWordsRsp {}

interface MergeWordReq {
    id_from: string;
    id_to: string;
}

interface MergeWordRsp {}
