import Button from "@mui/material/Button";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

// TODO: 优先使用BBDialog

// 通知消息:
// - 主题（可选）
// - 正文
// - 好的按钮， 取消按钮（可选）
interface AnnounceDialogProps {
    enable: boolean;
    setEnable: (b: boolean) => void;
    title?: string;
    content: any;
    showCancel?: boolean;
    onConfirm?: () => void;
    onClose?: () => void;
    size?: DialogProps["maxWidth"];
    okTitle?: string; // 好的按钮的文案
}

function AnnounceDialog(props: AnnounceDialogProps) {
    const { enable, setEnable, title, content, showCancel, onConfirm, onClose, size, okTitle } =
        props;

    const handleClose = () => {
        if (onClose) {
            onClose();
        }
        setEnable(false);
    };

    const handleConfirm = () => {
        if (onConfirm) {
            onConfirm();
        }
        setEnable(false);
    };
    return (
        <Dialog open={enable} onClose={handleClose} fullWidth maxWidth={size}>
            {title && <DialogTitle>{title}</DialogTitle>}
            <DialogContent>
                {content}
                {/* <DialogContentText>{content}</DialogContentText> */}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleConfirm}>{okTitle ? okTitle : "好的"}</Button>
                {showCancel && <Button onClick={handleClose}>取消</Button>}
            </DialogActions>
        </Dialog>
    );
}

export default AnnounceDialog;
