import { RateCount, RateScore } from "../../statistic/Count";
import { KnowCorrectRateRank } from "../../statistic/rank/RankData";
import { ExamInterpret } from "./Interpret";

export class ExamResult {
    correct: RateCount = new RateCount();
    avg_time_used_seconds: RateScore = new RateScore();
    vresult: SectionKindResult = new SectionKindResult();
    qresult: SectionKindResult = new SectionKindResult();
    iresult: SectionKindResult = new SectionKindResult();
    wresult: SectionKindResult = new SectionKindResult();
    knowledge: KnowledgeResult = new KnowledgeResult();
    difficulties: DifficultiesResult = new DifficultiesResult();
    total_score: number = 0;
    interpret: ExamInterpret = new ExamInterpret();
}

export class SectionKindResult {
    kind: number = 0;
    correct: RateCount = new RateCount();
    score: RateScore = new RateScore();
    rank: number = 0;
    avg_time_used_seconds: RateScore = new RateScore();
    question_types: QuestionKindResult[] = [new QuestionKindResult()];
    word_unknown: RateCount = new RateCount();
    word_fuzzy: RateCount = new RateCount();
}

export class SectionResult {
    correct: RateCount = new RateCount();
    not_answered: number = 0;
    skiped: number = 0;
    avg_diff: AvgDiff = new AvgDiff();
    avg_time_cost: AvgTimeCost = new AvgTimeCost();
}

export class QuestionKindResult {
    kind: number = 0;
    kind_str: string = "";
    kind_vq_name: string = "";
    correct: RateCount = new RateCount();
    avg_time_cost: AvgTimeCost = new AvgTimeCost();
}

export class KnowledgeResult {
    maths: KnowCorrectRateRank[] = [];
    readings: KnowCorrectRateRank[] = [];
}

export class AvgDiff {
    correct: RateScore = new RateScore();
    wrong: RateScore = new RateScore();
    all: RateScore = new RateScore();
}

export class AvgTimeCost {
    correct: RateScore = new RateScore();
    wrong: RateScore = new RateScore();
    all: RateScore = new RateScore();
}

export class DifficultiesResult {
    items: Difficulty[] = [];
}

export class Difficulty {
    difficulty: number = 0;
    avg_time_cost: AvgTimeCost = new AvgTimeCost();
}
