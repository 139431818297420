import { createTheme } from "@mui/material";
// When using TypeScript 4.x and above
import type {} from "@mui/lab/themeAugmentation";
import { PaletteOptions } from "@mui/material/styles";
import { ColorOverrides, Colors, ColorsType, CustomPalette } from "./color";

// palette color
declare module "@mui/material/styles" {
    interface Palette extends CustomPalette {}
    interface PaletteOptions extends CustomPalette {}
}

// button color
declare module "@mui/material/Button" {
    interface ButtonPropsColorOverrides extends ColorOverrides {}
}

declare module "@mui/material/IconButton" {
    interface IconButtonPropsColorOverrides extends ColorOverrides {}
}

declare module "@mui/material/TextField" {
    interface TextFieldPropsColorOverrides extends ColorOverrides {}
}

declare module "@mui/material/Chip" {
    interface ChipPropsColorOverrides extends ColorOverrides {}
}

declare module "@mui/material/AppBar" {
    interface AppBarPropsColorOverrides extends ColorOverrides {}
}

declare module "@mui/material/Badge" {
    interface BadgePropsColorOverrides extends ColorOverrides {}
}

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor: any) => augmentColor({ color: { main: mainColor } });

export const paletteOptions = Object.keys(Colors).reduce((acc, key) => {
    acc[key as keyof ColorsType] = createColor(Colors[key as keyof ColorsType]);
    return acc;
}, {} as PaletteOptions);

export const bbTheme = createTheme({
    components: {
        MuiButton: {
            defaultProps: {
                variant: "contained",
            },
            styleOverrides: {
                root: {
                    fontWeight: 700,
                },
            },
        },
    },
    typography: {
        button: {
            textTransform: "none",
        },
        fontFamily: [
            '"Microsoft YaHei"',
            '"微软雅黑"',
            '"Helvetica Neue"',
            "Helvetica",
            '"Hiragino Sans GB"',
            '"STHeitiSC-Light"',
            "Arial",
            "sans-serif",
        ].join(","),
    },
    palette: paletteOptions,
});
