import { EnumItem } from "../Enum";

export enum VIPTime {
    Day7 = 1,
    Month1 = 2,
    Month2 = 3,
    Month3 = 4,
    Manual = 100,
}

export const VIPTimeItems = [
    new EnumItem(VIPTime.Day7, "7天"),
    new EnumItem(VIPTime.Month1, "1月"),
    new EnumItem(VIPTime.Month2, "2月"),
    new EnumItem(VIPTime.Month3, "3月"),
    new EnumItem(VIPTime.Manual, "手动设置"),
];

// Code generated by transtool(v0.0.8).
export class Trade {
    id: string = "";
    kind: number = 0;
    param: string = "";
    order: number = 0;
    user_id: string = "";
    status: number = 0;
    create_time_stamp: number = 0;
    update_time_stamp: number = 0;
}

export enum TradeStatus {
    None = 0,
    PayInit = 1,
    PaySuccess = 2,
    PayFail = 3,
    ApplySuccess = 4,
}
