interface AuthData {
    user_id: string;
    user_name: string;
    exp: Date;
}

const TOKEN_KEY = "exam_simulate_token";

export type { AuthData };
export { TOKEN_KEY };
