import { BookTemplate, WordQuestionInfo } from "./Book";
import { CreateExamOption } from "./exam/Exam";

export class BookParam {
    type: number = 0;
    select_ids: BookParamSelectIDs = new BookParamSelectIDs();
    // 管理员编辑标签使用
    const_ids: BookParamConstIDs = new BookParamConstIDs();
    // 管理员编辑标签使用
    tailor_generate: BookParamTailorGenerate = new BookParamTailorGenerate();

    bank: BookParamBank = new BookParamBank();
}

export enum BookParamKind {
    None = 0,
    SelectIDs = 1,
    ConstIDs = 2,
    TailorGenerate = 3,
    OldWord = 4,
    Report = 5,
    Bank = 6,
    Simulate = 7,
    Word = 8,
}

export class BookParamReport {
    connect_book_ids: string[] = [];
}
export class BookParamWord {
    infos: WordQuestionInfo[] = [];
}
export class BookParamSelectIDs {
    ids: string[] = [];
    exam_type: number = 0;
    remove_correct_from_wrongs: boolean = false;
}
export class BookParamConstIDs {
    ids: string[] = [];
    exam_type: number = 0;
    tag_ids: string[] = [];
}

export class BookParamSimulate {
    option: CreateExamOption = new CreateExamOption();
}

export class GetShelfTemplatesRsp {
    templates: BookTemplate[] = [];
}

export class ReadTemplateRsp {
    content: string = "";
}
export class BookParamTailorGenerate {
    tag_ids: string[] = [];
}

export class BookParamBank {
    data_ids: string[] = [];
    skip_count: number = 0;
}
