import { Box, Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { useSelector } from "react-redux";
import { actionSetRightsDialog } from "../../store/dialog/reducer";
import { selectRightsDialog } from "../../store/dialog/selector";
import { useAppDispatch } from "../../store/hooks";
import rightsImg from "./asset/rights.jpg";

export function RightsDialog() {
    const dispatch = useAppDispatch();
    const open = useSelector(selectRightsDialog);

    const onClose = () => {
        dispatch(actionSetRightsDialog(false));
    };
    const onConfirm = () => {
        dispatch(actionSetRightsDialog(false));
    };
    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
            <DialogContent>
                <Box sx={{ mt: "20px", display: "flex", justifyContent: "center" }}>
                    <img src={rightsImg} width="1000px" alt="会员权益表" />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onConfirm} autoFocus>
                    关闭
                </Button>
            </DialogActions>
        </Dialog>
    );
}
