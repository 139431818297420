import { Answer, QuestionCollect, QuestionNote } from "./question/data/QuestionData";

export class Craft {
    user_id: string = "";
    id: string = "";
    wrong: QuestionWrong = new QuestionWrong();
    issue: QuestionIssue = new QuestionIssue();
    is_backend_data: boolean = false;
    collect: QuestionCollect = new QuestionCollect();
    note: QuestionNote = new QuestionNote();
    in_higher: boolean = false;
    card: Card = new Card();
}

export class Card {
    finished: boolean = false;
    finish_time_stamp: number = 0;
    finish_time_str: string = "";
    collected: boolean = false;
    collect_time_stamp: number = 0;
    collect_time_str: string = "";
}

export class QuestionWrong {
    answer: Answer = new Answer();
    wrong_time_str: string = "";
    wrong_from: string = "";
    exam_id: string = "";
}

export class QuestionIssue {
    answer: string = "";
    result: IssueResult = new IssueResult();
}

export class IssueResult {
    status: number = 0;
    score: number = 0;
    score_str: string = "";
    rank: number = 0;
    comment: string = "";
    progress: number | null = null;
    start_time_stamp: number = 0;
}

export enum IssueResultStatus {
    Empty = 0,
    Judging = 1,
    Finished = 2,
    Failed = 3,
}

export class CraftBank {
    is_backend_data: boolean = false;
    craftMap: Map<string, Craft> = new Map(); // key:id
}

export class GetCraftsRsp {
    crafts: Craft[] = [];
}
