import { Tag, TagBank } from "../../model/TagData";
import { RootState } from "../ReduxStore";

export const selectIdeaBank = (state: RootState) => state.ideaBank;

export const selectTagBank = (state: RootState) => state.tagBank;

export const selectSummaryBank = (state: RootState) => state.summaryBank;
export const selectDaily = (state: RootState) => selectSummaryBank(state).user_daily;
export const selectCalendayDays = (state: RootState) => selectSummaryBank(state).calendar_days;

export const selectCalcDisplay = (state: RootState) => state.calcDisplay;

export const selectBackdrop = (state: RootState) => state.backdrop;
export const selectBackdropCount = (state: RootState) => state.backdropCount;

export const getTagsFromBank = (tagIDs: string[], bank: TagBank): Tag[] => {
    return tagIDs.map((id) => bank.tagMap.get(id)).filter((t) => t) as Tag[];
};

export const selectWordBank = (state: RootState) => state.wordBank;

export const selectReport = (state: RootState) => state.report;
export const selectReportID = (state: RootState) => selectReport(state).id;

export const selectPageSource = (state: RootState) => state.pageSource;

// export const selectPermissionUsers = (state: RootState) => state.permissionManageRuntime.users;
