import { Box, BoxProps, Button, Stack } from "@mui/material";
import { useState } from "react";

interface SwitchProps {
    titles: any[];
    contents: any[];
    contentExtra?: any; // 显示在tab下, content上
    defaultIndex?: number;
    onChange?: (index: number) => void;
    width?: BoxProps["width"];
    marginBottom?: string;
    contentMargin?: string;
}

// 顶部2-3个按钮, 来切换展示底部内容
export function SwitchContent(props: SwitchProps) {
    const {
        titles,
        contents,
        defaultIndex,
        onChange,
        width,
        marginBottom,
        contentMargin,
        contentExtra,
    } = props;

    const [tab, setTab] = useState(defaultIndex !== undefined ? defaultIndex : 0);
    return (
        <>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Box
                    sx={{
                        width: width !== undefined ? width : "100vw",
                        marginBottom: marginBottom !== undefined ? marginBottom : "30px",
                    }}
                >
                    <Stack direction="row">
                        {titles.map((title, index) => (
                            <SwitchButton
                                key={index}
                                title={title}
                                index={index}
                                tab={tab}
                                setTab={setTab}
                                onChange={onChange}
                            />
                        ))}
                    </Stack>
                </Box>
            </Box>
            {contentExtra}
            {contents.map((content, index) => (
                <Box
                    key={index}
                    sx={{ m: contentMargin !== undefined ? contentMargin : "40px 10px 0 10px" }}
                >
                    {tab === index && content}
                </Box>
            ))}
        </>
    );
}

interface SwitchButtonProps {
    title: string;
    index: number;
    tab: number;
    setTab: (t: number) => void;
    onChange?: (index: number) => void;
}
function SwitchButton(props: SwitchButtonProps) {
    const { index, tab, setTab, title, onChange } = props;
    return (
        <Button
            size="large"
            fullWidth
            color={tab === index ? "primary" : "bbdisable"}
            sx={{
                ml: "10px",
                mr: "10px",
            }}
            onClick={() => {
                setTab(index);
                if (onChange !== undefined) {
                    onChange(index);
                }
            }}
        >
            {title}
        </Button>
    );
}
